import React, {useState} from "react";
import './App.scss';
import Landing from './containers/LandingVideo/Landing'
import Topbar from './containers/Topbar/Topbar'
import Loading from './containers/Loading/Loading'

function App() {
    const [showButtons, setShowButtons] = useState(false)
    const [loadPage, setLoadPage] = useState(false)

    const onClick = () => {
        setShowButtons(!showButtons)
    }

    return (
        <div className="App" >
            {/* <Topbar onClickPlay={onClick} showButtons={showButtons}></Topbar> */}
            {/* <Landing onClickPlay={onClick} showButtons={showButtons}></Landing> */}
            {loadPage && <Landing onClickPlay={onClick} showButtons={showButtons}></Landing>}
            {!loadPage && <Loading setLoadPage={setLoadPage}></Loading>}
            {/* <Loading setLoadPage={setLoadPage}></Loading> */}
        </div>
    );
};

export default App;
