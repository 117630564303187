import React from 'react'
import './Buttons.scss'
import { motion } from "framer-motion"


function Buttons() {
    return (
        <div>
            <div className="buttons">
                {/* <motion.div initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.2, delay: 0 }}
                    className="button">Portfolio coming soon</motion.div> */}
                <div className="elements elements-top">
                    <motion.div initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.2, delay: 0.15 }}
                        className="button">
                        <a href="https://fayemaidment.notion.site/gm-frens-5a9e6106f95244a8a150defe9c0c93eb#7e7744641f034e45adde589bf81131f9" target='_blank'>
                            <div>
                                Thesis
                            </div>
                        </a>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.2, delay: 0.15 }}
                        className="button">
                        <a href="https://fayemaidment.notion.site/fayemaidment/gm-frens-5a9e6106f95244a8a150defe9c0c93eb#2235ccc0e7c54aed87850e4839012ff1" target='_blank'>
                            <div>
                                FAQs
                            </div>
                        </a>
                    </motion.div>
                </div>
                <div className="elements elements-bottom">
                    <motion.div initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.2, delay: 0.15 }}
                        className="button">
                        <a href="https://fayemaidment.notion.site/fayemaidment/gm-frens-5a9e6106f95244a8a150defe9c0c93eb#388cd86b13c44ab4958600f5538c9b41" target='_blank'>
                            <div>
                                About me
                            </div>
                        </a>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.2, delay: 0.15 }}
                        className="button">
                        <a href="mailto:maidmentfaye@gmail.com" target='_blank'>
                            <div>
                                Contact me
                            </div>
                        </a>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default Buttons