import './Landing.scss'
import React, { useState, useEffect } from 'react'
import background from '../../assets/images/background.png'
import Buttons from '../Buttons/Buttons'
import video1 from '../../assets/videos/video3_3.mp4'
import image_video from '../../assets/images/video3_3.gif'
import bottom_corner from '../../assets/images/bottom_corner_2.png'
import { motion } from "framer-motion"


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height
  };
}


function Landing(props) {
  const { showButtons, onClickPlay } = props
  const [isMobile, setIsMobile] = useState(false)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className="bg">
      <motion.div initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2, delay: 0.2 }}
        className='Landing'>
        <div className="foreground" onClick={onClickPlay}>
          {!isMobile && <video muted id="robotVid" className="video" autoPlay loop playsinline>
            <source src={video1} type="video/mp4" />
          </video>}
          {isMobile && <img src={image_video} alt="" className="image_video" />}
          <div className='faye'>
            <img src={bottom_corner} onClick={onClickPlay} alt="bottom_corner" />
            <a href="https://twitter.com/maidmentfaye" target="_blank">
              <div className="clickTwitter"></div>
            </a>
            <a href="mailto:maidmentfaye@gmail.com" target="_blank">
              <div className="clickGmail"></div>
            </a>
          </div>

          {/* <img src={background} alt="background" className='foreground-img' /> */}
          {showButtons && <Buttons></Buttons>}
        </div>
      </motion.div>
    </div>
  )
}

export default Landing