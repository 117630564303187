import React from 'react'
import './Topbar.scss'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

function Topbar(props) {

    const {onClickPlay, showButtons} = props

    return (
        <div className='Topbar'>

            <div className="logo">Faye Maidment, angel investor</div>
            {/* {!showButtons && <div className='Button' onClick={onClickPlay}>▶</div>}
            {showButtons && <div className='Button' onClick={onClickPlay}>pause</div>} */}

            {!showButtons && <PlayArrowIcon className='logo' onClick={onClickPlay} />}
            {showButtons && <PauseIcon className='logo' onClick={onClickPlay} />}
        </div>
    )
}

export default Topbar