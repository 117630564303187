import React, { Component } from 'react';
import './Loading.scss'
import image from '../../assets/images/icon_bg.png'
import fondo from '../../assets/images/fondo.png'
import LinearProgress from '@mui/material/LinearProgress';
import { createTheme } from '@mui/material/styles';



function Loading(props) {
    const [progress, setProgress] = React.useState(0);
    const {setLoadPage} = props

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                const diff = Math.random() * 10;
                if (oldProgress === 100){
                    setLoadPage(true)
                }
                return Math.min(oldProgress + diff, 100);
            });
        }, 100);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className='Loading'>
            <div className="background">
                <img src={fondo} alt="logo" />
            </div>
            <div className="icon">
                <img src={image} alt="logo" />

                <div className="text1">
                    Faye Maidment
                </div>
                <div className="text2">
                    Angel Investor
                </div>
                <div className="loading">
                    <LinearProgress variant="determinate" value={progress} sx={{
                        backgroundColor: 'rgb(253, 212, 250, 0.4)',
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: 'rgb(253, 212, 250)'
                        }
                    }} />
                </div>
            </div>

        </div>
    )
}

export default Loading